<template lang='pug'>
coins-info(v-if="coinsInfo", :info="coinsInfo")
pagination(v-if="pages > 1", :current-page="page", :pages="pages")
</template>

<script>
import CoinsInfo from '../components/tables/CoinsInfo'
import Pagination from '../components/Pagination'
import { getCoins } from '../assets/scripts/API'
import { useI18n } from 'vue-i18n'
import { addMeta } from '../assets/scripts/add-meta'

export default {
  name: 'CoinsPage',
  components: { Pagination, CoinsInfo },
  data () {
    return {
      page: 1,
      pages: 1,
      coinsInfo: null
    }
  },
  methods: {
    async getDataPage (page) {
      const data = await getCoins(page)
      this.coinsInfo = data
      this.pages = data.pages
      this.page = data.page
    }
  },
  mounted () {
    this.getDataPage(this.page)
    addMeta(this.t('title'), this.t('description'))
  },
  beforeRouteUpdate (to, from, next) {
    this.page = +to.query.page
    this.getDataPage(this.page)
    next()
  },
  watch: {
    '$i18n.locale' () {
      addMeta(this.t('title'), this.t('description'))
    }
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
      messages: {
        ru: {
          title: 'Все криптовалюты, их алгоримы и количество майнинг пулов для них',
          description: 'Полный список всех криптовалют, доступных на портале cryptopack.ru Возможность просмотра алгоритма и количества майнинг пулов'
        },
        en: {
          title: 'All cryptocurrencies, their algorithms and the number of mining pools for them',
          description: 'A complete list of all cryptocurrencies available on the cryptopack.ru portal Ability to view the algorithm and the number of mining pools'
        },
        es: {
          title: 'Todas las criptomonedas, sus algoritmos y el número de pools de minería para ellas',
          description: 'Una lista completa de todas las criptomonedas disponibles en el portal cryptopack.ru Capacidad para ver el algoritmo y la cantidad de grupos de minería'
        },
        pt: {
          title: 'Todas as criptomoedas, seus algoritmos e o número de pools de mineração para eles',
          description: 'Uma lista completa de todas as criptomoedas disponíveis no portal cryptopack.ru Capacidade de visualizar o algoritmo e o número de pools de mineração'
        },
        de: {
          title: 'Alle Kryptowährungen, ihre Algorithmen und die Anzahl der Mining-Pools für sie',
          description: 'Eine vollständige Liste aller auf dem Portal cryptopack.ru verfügbaren Kryptowährungen Möglichkeit, den Algorithmus und die Anzahl der Mining-Pools anzuzeigen'
        },
        fr: {
          title: 'Toutes les crypto-monnaies, leurs algorithmes et le nombre de pools de minage pour eux',
          description: 'Une liste complète de toutes les crypto-monnaies disponibles sur le portail cryptopack.ru Possibilité de visualiser l\'algorithme et le nombre de pools de minage'
        },
        it: {
          title: 'Tutte le criptovalute, i loro algoritmi e il numero di mining pool per loro',
          description: 'Un elenco completo di tutte le criptovalute disponibili sul portale cryptopack.ru Possibilità di visualizzare l\'algoritmo e il numero di mining pool'
        },
        pl: {
          title: 'Wszystkie kryptowaluty, ich algorytmy i liczba pul wydobywczych dla nich',
          description: 'Pełna lista wszystkich kryptowalut dostępnych na portalu cryptopack.ru Możliwość podglądu algorytmu i liczby pul wydobywczych'
        },
        zh: {
          title: '所有加密貨幣、它們的算法以及它們的礦池數量',
          description: 'cryptopack.ru 門戶網站上所有可用加密貨幣的完整列表 能夠查看算法和礦池數量'
        },
        ja: {
          title: 'すべての暗号通貨、それらのアルゴリズム、およびそれらのマイニングプールの数',
          description: 'cryptopack.ruポータルで利用可能なすべての暗号通貨の完全なリストアルゴリズムとマイニングプールの数を表示する機能'
        }
      }
    })
    return { t }
  }
}
</script>
